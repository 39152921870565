//@ts-nocheck
import {
  Avatar,
  Box,
  Button,
  createStyles,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CallIcon from "@material-ui/icons/Call";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import RoomIcon from "@material-ui/icons/Room";
import React from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage1 } from "./assets";
import BookingDetailPopup from "./BookingDetailPopup.web";
import withDialog from "../../../components/src/withDialog.web";
import CustomerBookingsController, {
  Props,
} from "./CustomerBookingsController.web";
import CheckInPopup from "./CheckInPopup.web";
import BoatReview from "./BoatReview.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";

const sideBarLinks = [
  { displayName: "My Documents", route: "/userDashboard/myDocuments" },
  { displayName: "My Bookings", route: "/userDashboard/myBookings" },
  { displayName: "Messages", route: "/userDashboard/messages" },
  {
    displayName: "Personal Information",
    route: "/userDashboard/personalInformation",
  },
  { displayName: "Payment", route: "/userDashboard/payment" },
  { displayName: "Account Settings", route: "/userDashboard/accountSettings" },
];

class UserBookings extends CustomerBookingsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          this.getUserCurrentBooking();
          this.getUserPastBooking()
          this.getUserUpcomingtBooking();
        }
      );
    }
  }
  openBookingDetailsDialog = (data) => {
    this.props
      .openDialogBox({
        title: "Booking Details",
        withCustomDialog: false,
        renderedComponent: BookingDetailPopup,
        dataToPass: {
          bookingDetail: data,
          isApproved: false,
          isRenterView: true,
        },
        catchOnCancel: true,
      })
      .then((data) => {});
  };

  openCancelDialog = (data) => {
    const onClose = async (result) => {
      if(result === 'Yes'){
        this.cancelBooking(data)
      }
    }
    this.props.openConfirmDialog(
      'Cancelation',`Are you sure you want to cancel this booking` ,onClose
    )    
  };

  openCheckInDialog = (data) => {
    this.props
      .openDialogBox({
        title: "Check-In",
        withCustomDialog: false,
        renderedComponent: CheckInPopup,
        dataToPass: { bookingDetail: data },
        catchOnCancel: true,
      })
      .then((id) => {
        if (id) {
          this.props.showToast({
            type: "success",
            message: "Checked In Succssfully",
          });
          this.getUserCurrentBooking();
          this.getUserUpcomingtBooking();
          this.getUserPastBooking();
        }
      });
  };
  openCheckOutDialog = (data) => {
    this.props.openDialogBox({
      title: "Review",
      withCustomDialog: false,
      renderedComponent: BoatReview,
      dataToPass: { bookingDetail: data },
      catchOnCancel: true,
    }).then((id) => {
      if (id) {
        this.props.showToast({
          type: "success",
          message: "Checked Out Succssfully",
        });
        this.getUserCurrentBooking();
        this.getUserUpcomingtBooking();
        this.getUserPastBooking();
      }
    });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  goToSelectedRoute = (routeName) => {
    this.handleClose();
    this.setState({ mobileOpen: false });
    const { history } = this.props;
    history.push(routeName);
  };

  render() {
    const { classes } = this.props;
    const currentBookings = this.state.userCurrentBookings || []
    const upcomingBookings = this.state.userUpcomingBookings || []
    const pastBookings = this.state.userPastBookings || []
    return (
      <Grid container className={classes.root}>
        <>
          <Hidden xsDown>
            <Grid
              item
              xs={0}
              sm={1}
              md={2}
              container
              className={classes.sideBar}
            >
              <Grid item xs={12}>
                <Typography align="center" className={classes.sideBarHeading}>
                  My Account
                </Typography>
                <List dense={false}>
                  {sideBarLinks.map((item) => {
                    return (
                      <NavLink
                        to={item.route}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={item.displayName}
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <AccountCircle />
                          </ListItemAvatar>
                          <ListItemText
                            primary={item.displayName}
                            classes={{ primary: classes.sideLink }}
                          />
                        </ListItem>
                      </NavLink>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={11} md={10} className={classes.rightRoot}>
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <>
                  <Box pt={2} className={classes.secondBox}>
                    <Typography variant="h5" className={classes.mainHeading}>
                      My Bookings
                    </Typography>
                    <Box pt={1.25}>
                      <Typography variant="h6" className={classes.mainSubText}>
                        Your  bookings will be shown here
                      </Typography>
                    </Box>
                  </Box>
                  <Grid container justify={"space-between"}>
                    <Grid item xs={12} md={12}>
                      <Box py={1.25}>
                        <Typography
                          variant="h5"
                          className={classes.blueHeading}
                        >
                          Current Bookings
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify={"space-between"}>
                    <Grid item xs={12} md={12}>
                      {currentBookings.length > 0 ? currentBookings.map((data) => (
                        <Box className={classes.box} py={3} key={data.id}>
                          <Grid container spacing={3} pl={0}>
                            <Grid item xs={12} md={5}>
                              <Grid container spacing={2}>
                                 <Grid xs={12} item>
                                 <img
                                src={
                                  data?.attributes?.boat?.attributes
                                    ?.thumbnail_image?.url
                                    ? data?.attributes?.boat?.attributes
                                        ?.thumbnail_image?.url
                                    : boatImage1
                                }
                                width="100%"
                                className={classes.boatImage}
                              />
                                 </Grid>
                                 <Grid xs={10} item>
                                 <Button
                                            color="primary"
                                            variant="outlined"
                                            className={classes.roundButtons}
                                            onClick={() => {
                                              this.props.history.push({pathname :  "/userDashboard/selectedBoatDetails",state : {boatId : data?.attributes?.boat_id}});  
                                            }}
                                          >
                                            Boat Detail
                                          </Button>
                                 </Grid>
                              </Grid>
                              
                            </Grid>
                            <Grid item xs={12} md={7}>
                              <Box pb={3}>
                                <Typography
                                  variant="h5"
                                  className={classes.blueHeading}
                                >
                                  {data?.attributes?.boat?.attributes?.name}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.location}
                                >
                                  {
                                    data?.attributes?.boat?.attributes?.address
                                      ?.address1
                                  }
                                  ,
                                  {
                                    data?.attributes?.boat?.attributes?.address
                                      ?.city
                                  }
                                </Typography>
                              </Box>
                              <Grid container spacing={3}>
                                <Grid container item xs={12}>
                                  <Grid item xs={7}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          className={classes.detailName}
                                        >
                                          Contact Marina
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {data?.attributes?.marina?.data?.attributes?.name}
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {data?.attributes?.marina?.data?.attributes?.address?.address1 || "-"}, {data?.attributes?.marina?.data?.attributes?.address?.city || "-"}
                                        </Typography>
                                      </Box>
                                      <Box display="flex" alignItems="center">
                                        <Box px={1}>
                                          <CallIcon
                                            color="primary"
                                            variant="filled"
                                          />
                                        </Box>
                                        <Box px={1}>
                                          <RoomIcon
                                            color="primary"
                                            variant="filled"
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={5}>
                                    <Box
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          className={classes.detailName}
                                        >
                                          Trip date
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {moment(
                                            new Date(
                                              data?.attributes?.trip_date
                                            ).getTime()
                                          ).format("DD/MM/YYYY")}
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {moment(
                                            new Date(
                                              data?.attributes?.start_time
                                            ).getTime()
                                          ).format("hh:mm")}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                
                                <Grid xs={12} item>
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Grid item xs={4}>
                                      <ListItem disableGutters>
                                        <ListItemAvatar>
                                          <Avatar
                                            src={
                                              data?.attributes?.boat?.attributes
                                                ?.account?.avatar.url
                                            }
                                          ></Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                          secondary={"Call Owner"}
                                          primary={`${data?.attributes?.boat?.attributes?.account?.first_name} ${data?.attributes?.boat?.attributes?.account?.last_name}`}
                                        ></ListItemText>
                                      </ListItem>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Grid
                                        container
                                        spacing={1}
                                        justify="flex-end"
                                      >
                                        <Grid item>
                                          <Button
                                            color="primary"
                                            variant="outlined"
                                            className={classes.roundButtons}
                                            onClick={() => {
                                              this.openBookingDetailsDialog(
                                                data
                                              );
                                            }}
                                          >
                                            Booking Detail
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          {data?.attributes?.checkin_status ===
                                            "pending" && (
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className={`${classes.checkInButton} ${classes.roundButtons}`}
                                              onClick={() =>
                                                this.openCheckInDialog(data)
                                              }
                                            >
                                              Check-in
                                            </Button>
                                          )}
                                          {data?.attributes?.checkin_status ===
                                            "checkin" && (
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className={`${classes.checkInButton} ${classes.roundButtons}`}
                                              onClick={() =>
                                                this.openCheckOutDialog(data)
                                              }
                                            >
                                              Check-out
                                            </Button>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid xs={12} item>
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Grid item xs={4}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          className={classes.detailName}
                                        >
                                          Check out Time
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {moment(data?.attributes?.end_time).format("DD MMMM YYYY")}
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {moment(data?.attributes?.end_time).format("hh:mm A")}
                                        </Typography>
                                      </Box>
                                     </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                    >
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          className={classes.detailName}
                                        >
                                          End Time
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {moment(
                                            new Date(
                                              data?.attributes?.end_time
                                            ).getTime()
                                          ).format("hh:mm:ss")}
                                        </Typography>
                                      </Box>
                                    </Box>
                                 
                                     </Grid>
                                  </Grid>
                                </Grid>
                              
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      )) : <Typography variant="h5"
                      className={classes.mainHeading} align="center">No Booking Available</Typography>}
                    </Grid>
                  </Grid>
                  <Grid container justify={"space-between"}>
                    <Grid item xs={12} md={12}>
                      <Box py={1.25}>
                        <Typography
                          variant="h5"
                          className={classes.blueHeading}
                        >
                          Upcoming Bookings
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify={"space-between"}>
                    <Grid item xs={12} md={12}>
                      {upcomingBookings.length > 0 ? upcomingBookings.map((data) => (
                        <Box className={classes.box} py={3} key={data.id}>
                          <Grid container spacing={3} pl={0}>
                            <Grid item xs={12} md={5}>
                              <img
                                src={
                                  data?.attributes?.boat?.attributes
                                    ?.thumbnail_image?.url
                                    ? data?.attributes?.boat?.attributes
                                        ?.thumbnail_image?.url
                                    : boatImage1
                                }
                                width="100%"
                                className={classes.boatImage}
                              />
                            </Grid>
                            <Grid item xs={12} md={7}>
                              <Box pb={3}>
                                <Typography
                                  variant="h5"
                                  className={classes.blueHeading}
                                >
                                  {data?.attributes?.boat?.attributes?.name}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.location}
                                >
                                  {
                                    data?.attributes?.boat?.attributes?.address
                                      ?.address1
                                  }
                                  ,
                                  {
                                    data?.attributes?.boat?.attributes?.address
                                      ?.city
                                  }
                                </Typography>
                              </Box>
                              <Grid container>
                                <Grid container item xs={12}>
                                  <Grid item xs={7}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          className={classes.detailName}
                                        >
                                          Contact Marina
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {data?.attributes?.marina?.data?.attributes?.name}
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {data?.attributes?.marina?.data?.attributes?.address?.address1 || "-"}, {data?.attributes?.marina?.data?.attributes?.address?.city || "-"}
                                        </Typography>
                                      </Box>
                                      <Box display="flex" alignItems="center">
                                        <Box px={1}>
                                          <CallIcon
                                            color="primary"
                                            variant="filled"
                                          />
                                        </Box>
                                        <Box px={1}>
                                          <RoomIcon
                                            color="primary"
                                            variant="filled"
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={5}>
                                    <Box
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          className={classes.detailName}
                                        >
                                          Trip date
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {moment(
                                            new Date(
                                              data?.attributes?.trip_date
                                            ).getTime()
                                          ).format("DD/MM/YYYY")}
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {moment(
                                            new Date(
                                              data?.attributes?.start_time
                                            ).getTime()
                                          ).format("hh:mm")}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid xs={12} item>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      {data?.attributes?.approval_status ||
                                        (data?.attributes?.approval_status ===
                                          "pending" && (
                                          <Button
                                            startIcon={
                                              <ErrorIcon
                                                className={classes.trashbin}
                                              />
                                            }
                                            className={classes.cancelButton}
                                          >
                                            Approval Pending
                                          </Button>
                                        ))}
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        onClick={()=>{this.openCancelDialog(data)}}
                                        startIcon={
                                          <CancelIcon
                                            className={classes.trashbin}
                                          />
                                        }
                                        className={classes.cancelButton}
                                      >
                                        Cancellation
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid xs={12} item>
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Grid item xs={4}>
                                      <ListItem disableGutters>
                                        <ListItemAvatar>
                                          <Avatar
                                            src={
                                              data?.attributes?.boat?.attributes
                                                ?.account?.avatar.url
                                            }
                                          ></Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                          secondary={"Call Owner"}
                                          primary={`${data?.attributes?.boat?.attributes?.account?.first_name} ${data?.attributes?.boat?.attributes?.account?.last_name}`}
                                        ></ListItemText>
                                      </ListItem>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Grid
                                        container
                                        spacing={1}
                                        justify="flex-end"
                                      >
                                        <Grid item>
                                          <Button
                                            color="primary"
                                            variant="outlined"
                                            className={classes.roundButtons}
                                            onClick={() => {
                                              this.openBookingDetailsDialog(
                                                data
                                              );
                                            }}
                                          >
                                            Booking Detail
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          {data?.attributes?.checkin_status ===
                                            "pending" && (
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className={`${classes.checkInButton} ${classes.roundButtons}`}
                                              onClick={() =>
                                                this.openCheckInDialog(data)
                                              }
                                            >
                                              Check-in
                                            </Button>
                                          )}
                                          {data?.attributes?.checkin_status ===
                                            "checkin" && (
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className={`${classes.checkInButton} ${classes.roundButtons}`}
                                              onClick={() =>
                                                this.openCheckOutDialog(data)
                                              }
                                            >
                                              Check-out
                                            </Button>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      )) : <Typography variant="h5"
                      className={classes.mainHeading} align="center">No Booking Available</Typography>
                      }
                    </Grid>
                  </Grid>
                  <Grid container justify={"space-between"}>
                    <Grid item xs={12} md={12}>
                      <Box py={1.25}>
                        <Typography
                          variant="h5"
                          className={classes.blueHeading}
                        >
                          Past Bookings
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify={"space-between"}>
                    <Grid item xs={12} md={12}>
                      {pastBookings.length > 0 ? pastBookings.map((data) => (
                        <Box className={classes.box} py={3} key={data.id}>
                          <Grid container spacing={3} pl={0}>
                            <Grid item xs={12} md={5}>
                              <img
                                src={
                                  data?.attributes?.boat?.attributes
                                    ?.thumbnail_image?.url
                                    ? data?.attributes?.boat?.attributes
                                        ?.thumbnail_image?.url
                                    : boatImage1
                                }
                                width="100%"
                                className={classes.boatImage}
                              />
                            </Grid>
                            <Grid item xs={12} md={7}>
                              <Box pb={3}>
                                <Typography
                                  variant="h5"
                                  className={classes.blueHeading}
                                >
                                  {data?.attributes?.boat?.attributes?.name}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.location}
                                >
                                  {
                                    data?.attributes?.boat?.attributes?.address
                                      ?.address1
                                  }
                                  ,
                                  {
                                    data?.attributes?.boat?.attributes?.address
                                      ?.city
                                  }
                                </Typography>
                              </Box>
                              <Grid container>
                                <Grid container item xs={12}>
                                  <Grid item xs={7}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          className={classes.detailName}
                                        >
                                          Contact Marina
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {data?.attributes?.marina?.data?.attributes?.name}
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {data?.attributes?.marina?.data?.attributes?.address?.address1 || "-"}, {data?.attributes?.marina?.data?.attributes?.address?.city || "-"}
                                        </Typography>
                                      </Box>
                                      <Box display="flex" alignItems="center">
                                        <Box px={1}>
                                          <CallIcon
                                            color="primary"
                                            variant="filled"
                                          />
                                        </Box>
                                        <Box px={1}>
                                          <RoomIcon
                                            color="primary"
                                            variant="filled"
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={5}>
                                    <Box
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          className={classes.detailName}
                                        >
                                          Trip date
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {moment(
                                            new Date(
                                              data?.attributes?.trip_date
                                            ).getTime()
                                          ).format("DD/MM/YYYY")}
                                        </Typography>
                                        <Typography
                                          variant="h7"
                                          component="div"
                                          className={classes.timeDetails}
                                        >
                                          {moment(
                                            new Date(
                                              data?.attributes?.start_time
                                            ).getTime()
                                          ).format("hh:mm")}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid xs={12} item>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      {!data?.attributes?.approval_status ||
                                        (data?.attributes?.approval_status ===
                                          "pending" && (
                                          <Button
                                            startIcon={
                                              <ErrorIcon
                                                className={classes.trashbin}
                                              />
                                            }
                                            className={classes.cancelButton}
                                          >
                                            Approval Pending
                                          </Button>
                                        ))}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid xs={12} item>
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Grid item xs={4}>
                                      <ListItem disableGutters>
                                        <ListItemAvatar>
                                          <Avatar
                                            src={
                                              data?.attributes?.boat?.attributes
                                                ?.account?.avatar.url
                                            }
                                          ></Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                          secondary={"Call Owner"}
                                          primary={`${data?.attributes?.boat?.attributes?.account?.first_name} ${data?.attributes?.boat?.attributes?.account?.last_name}`}
                                        ></ListItemText>
                                      </ListItem>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Grid
                                        container
                                        spacing={1}
                                        justify="flex-end"
                                      >
                                        <Grid item>
                                          <Button
                                            color="primary"
                                            variant="outlined"
                                            className={classes.roundButtons}
                                            onClick={() => {
                                              this.openBookingDetailsDialog(
                                                data
                                              );
                                            }}
                                          >
                                            Booking Detail
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      )) : <Typography variant="h5"
                      className={classes.mainHeading} align="center">No Booking Available</Typography>}
                    </Grid>
                  </Grid>
                </>
              </Paper>
            </main>
          </Grid>
        </>
      </Grid>
    );
  }
}
const UserBookingsWithRouter = withRouter(UserBookings);
const UserBookingsAlertBox = withAlertBox(UserBookingsWithRouter);
const UserBookingsLoader = withLoader(UserBookingsAlertBox);
const UserBookingsToast = withToast(UserBookingsLoader);
export default withStyles((theme) =>
  createStyles({
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    fileInput: {
      display: "none",
    },
    sectionHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    preView: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#000000",
    },
    primaryText: {
      fontFamily: "Montserrat",
      fontSize: "10px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    secondaryText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(4),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
      "@media only screen and (max-width: 768px)": {
        padding: "0px",
      },
    },
    sideBar: {
      height: "calc(100vh - 72px)",
      backgroundColor: "#ffffff",
    },
    rightRoot: {
      backgroundImage:
        "linear-gradient(180deg, rgba(0,0,255,1) 15%, rgba(245,245,245,1) 15%)",
    },
    sideLinkHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    sideLink: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#343a40",
      cursor: "pointer",
      textAlign: "center",
      display: "block",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
        textTransform: "none",
      },
    },
    activeSideLink: {
      backgroundColor: "rgb(1,116,207,0.1)",
      color: "#343a40",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
    mainBox: {
      fontFamily: "Montserrat",
      backgroundColor: "#0174cf",
      "@media only screen and (max-width: 768px)": {
        paddingLeft: "0px",
      },
    },
    mainSubText: {
      fontWeight: "normal",
    },
    secondBox: {
      fontSize: "1.25rem",
      backgroundColor: "#ffffff",
      "@media only screen and (max-width: 768px)": {
        paddingLeft: "16px",
      },
    },
    shiftContainerUp: {
      position: "relative",
      top: "-24px",
    },
    greyBox: {
      "@media only screen and (max-width: 768px)": {
        paddingLeft: "0px",
      },
      fontFamily: "Montserrat",
      backgroundColor: "#f5f5f5",
    },
    location: {
      color: "#343a40",
      fontWeight: 500,
    },
    box: {
      border: "1px #dee2e3",
      borderStyle: "none none solid none",
    },
    mainHeading: {
      fontWeight: 600,
    },
    removePadding: {
      margin: "-24px",
    },
    blueHeading: {
      fontFamily: "Montserrat",
      color: "#004277",
      fontWeight: 600,
    },
    smallText: {
      fontFamily: "Montserrat",
      color: "#343a40",
    },
    amount: {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: 600,
      color: "#000000",
    },
    detailName: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 600,
      color: "#343a40",
    },
    blueDetails: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      color: "#0174cf",
    },
    paidStatus: {
      fontFamily: "Montserrat",
      color: "#b8b8b8",
    },
    timeDetails: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      color: "#343a40",
    },
    callButton: {
      height: "0px",
      padding: "0px",
    },
    tabPanelcolor: {
      fontFamily: "Montserrat",
      backgroundColor: "white",
      color: "black",
    },
    checkInButton: {
      fontFamily: "Montserrat",
    },
    roundButtons: {
      fontFamily: "Montserrat",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
    },
    avatar: {
      fontFamily: "Montserrat",
      height: "60px",
      width: "60px",
    },
    removeBoxShadow: {
      fontFamily: "Montserrat",
      boxShadow: "none",
      border: "1px #dee2e3",
      borderStyle: "none none solid none",
    },
    selected: {
      fontFamily: "Montserrat",
      opacity: 1.0,
      color: "black",
    },
    notselected: {
      fontFamily: "Montserrat",
      opacity: 0.3,
      color: "#b8b8b8",
    },
    boatImage: {
      height: "280px",
      borderRadius: "5px",
    },
    trashbin: {
      opacity: 0.5,
    },
    textCentre: {
      verticalAlign: "text-bottom",
      // "@media only screen and (min-width: 768px)": {
      //   paddingLeft:"12px"
      // }
      paddingLeft: "12px",
    },
    cancelButton: {
      color: theme.palette.error.main,
    },
    roundImage: {
      borderRadius: "5px",
    },
  })
)(withConfirmBox(withDialog(UserBookingsToast)));
