//@ts-nocheck
import React from "react";
import clsx from 'clsx';
import BookBoatController, { Props } from "./BookBoatController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  Button,
  Avatar,
  Paper,
  Divider,
  Box,
  Link
} from "@material-ui/core";
import * as Yup from "yup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckIcon from '@material-ui/icons/Check';
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import { boatImageDemo1, boatImageDemo2 } from "./assets";
import DateFnsUtils from "@date-io/date-fns";
import ReviewDocumet from "./ReviewDocumet.web";
import BoatReview from "./BoatReview.web";

import {
  logo,boatDetail,
  boatImage,
  calendar,
  watch,
  captionIcon,
  team,
  clock,
  error
} from "./assets";
import { Formik, Form, Field } from "formik";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import moment from "moment";
const boatBookSchema = Yup.object().shape({
  tripDate: Yup.date().required("this field is required"),
  noOfPassenger: Yup.string().required("this field is required"),
  tripDuration: Yup.object().nullable().required("this field is required"),
  tripTime: Yup.object().nullable().required("this field is required"),
  captionIncluded : Yup.string().required("this field is required"),
});
class BookBoat extends BookBoatController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatId = this.props?.history?.location?.state?.boatId || 1;
    const accountInfo = await StorageProvider.get("accountInfo");
    if (authToken && boatId) {
      this.setState(
        {
          authToken: authToken,
          boatId: boatId,
          accountId: JSON.parse(accountInfo)?.id,
        },
        () => {
          this.getBoatDetail();
        }
      );
    }
  }
  getPrice = (boatPrice = {},duration) => {
    if(duration){
      if(duration === 'half_day'){
        return `$ ${boatPrice["half_day_rate"] || "-"}`
      } else if(duration === 'full_day'){
        return `$ ${boatPrice["full_day_rate"] || "-"}`
      } else if(duration === 'twentyfour_hours'){
        return `$ ${boatPrice["twenty_four_hours_rate"] || "-"}`
      } else if(duration === 'three_days'){
        return `$ ${boatPrice["three_days_rate"] || "-"}`
      }
      else if(duration === 'seven_days'){
        return `$ ${boatPrice["seven_days_rate"] || "-"}`
      }
    } else {
      return `$ ${boatPrice["half_day_rate"] || "-"}`
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  openPassengersMenu = (event,setFieldTouched) => {
    this.setState({
      passengersAnchorEl: event.currentTarget,
    },()=>{
      setFieldTouched("noOfPassenger",true)
    });
  };
  openTripTimeMenu = (event,setFieldTouched) => {
    this.setState({
      tripTimeAnchorEl: event.currentTarget,
    },()=>{
      setFieldTouched("tripTime",true)
    });
  };
  selectPassengersAndCloseMenu = () => {
    this.setState({
      passengersAnchorEl: null,
    });
  };
  selectTripTimeAndCloseMenu = () => {
    this.setState({
      tripTimeAnchorEl: null,
    });
  };
  openDurationMenu = (event,setFieldTouched) => {
    this.setState({
      durationAnchorEl: event.currentTarget,
    },()=>{
      setFieldTouched("tripDuration",true)
    });
  };
  selectDurationAndCloseMenu = () => {
    this.setState({
      durationAnchorEl: null,
    });
  };
  openCaptionMenu = (event,setFieldTouched) => {
    this.setState({
      captionAnchorEl: event.currentTarget,
    },()=>{
      setFieldTouched("captionIncluded",true)
    });
  };
  selectCaptionAndCloseMenu = () => {
    this.setState({
      captionAnchorEl: null,
    });
  };
  openReviewDocument = () => {
    this.props.openDialogBox({
      title : 'Request for Signature',
      withCustomDialog : false,
      renderedComponent : ReviewDocumet,
      dataToPass : {boat : this.state.boatDetails},
      catchOnCancel : true
    }).
    then((data)=>{
      if(data){
        this.props.showToast({
          type : 'success',
          message : 'Message sent successfully'
        })
      }
    })
  }
  openBoatReview = () => {
    this.props.openDialogBox({
      title : 'Review',
      withCustomDialog : false,
      renderedComponent : BoatReview,
      dataToPass : {boatId : this.state.boatId,boatDetails : this.state.boatDetails},
      catchOnCancel : true
    }).
    then((data : any)=>{
      if(data){
        this.props.showToast({
          type : 'success',
          message : 'Review submitted successfully'
        })
      }
    })
  }
  
  render() {
    const { classes } = this.props;
    let required_docs : Array<string> = Object.keys(this.state.boatDetails.required_doc || {}) ;
    
    const isAllDocumentPresent = required_docs.every((required_doc)=>{
      const value = (this.state.boatDetails.required_doc[required_doc] + "") === "false" ? false : true; 
      if(value){
        return this.state.userDocuments.indexOf(required_doc) > -1
      } else {
        return true
      }
    })
    const isDocumentNotRequired = required_docs.every((required_doc)=>{
      const value = (this.state.boatDetails.required_doc[required_doc] + "") === "false" ? false : true; 
      return !value
    })
    const shiftDays = [
      {
          "id": 1,
          "start_time": "2021-06-30T08:00:00.000",
          "end_time": "2021-06-30T12:00:00.000",
          "created_at": "2021-06-30T08:45:19.976",
          "updated_at": "2021-06-30T08:45:19.976"
      },
      {
          "id": 2,
          "start_time": "2021-06-30T14:00:00.000",
          "end_time": "2021-06-30T20:00:00.000",
          "created_at": "2021-06-30T08:45:46.481",
          "updated_at": "2021-06-30T08:45:46.481"
      }
    ].map((shift)=>{
      return {...shift,time : `${moment(shift.start_time).format("hh:mm A")} - ${moment(shift.end_time).format("hh:mm A")}`}
    });
    return (
      <>
        <Grid container className={classes.root}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.root}>
              <main className={classes.layout}>
                <Paper className={classes.paper}>
                  <Typography component="h1" variant="h4" className={classes.mainHeading}>
                    Booking Details
                  </Typography>
                  <Box p={2}/>
                  <Grid container justify="space-between" spacing={4}>
                    <Grid item md={7} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={2} justify="space-between">
                            <Grid xs={10} item>
                              <Typography className={clsx(classes.subHeading,classes.firstSubHeading)}>Your Selection:</Typography>
                            </Grid>
                            <Grid
                              className={classes.hotelMainImage}
                              style={{
                                backgroundImage: `url(${
                                  this.state.boatDetails?.thumbnail_image
                                    ?.url || boatImageDemo1
                                })`,
                                minHeight:'230px',
                                maxWidth: "400px"
                              }}
                              xs={8}
                              item
                            />
                            <Grid item xs={10}>
                              <Typography
                                gutterBottom
                                className={classes.boatName}
                              >
                                {`${this.state.boatDetails?.name}`}
                              </Typography>
                              <Typography
                                gutterBottom
                                className={classes.boatAddress}
                              >
                                {`${this.state.boatDetails?.address?.address1},${this.state.boatDetails?.address?.city}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={10} xs={12}>
                          <List>
                            <ListItem dense={true}>
                              <ListItemText primary="Boat Price" classes={{primary:classes.boatAddress}} />
                              <ListItemSecondaryAction className={classes.amount}>
                                 { this.state.selectedBoatPrice || "$ 0"}
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="middle" />
                            <ListItem dense={true}>
                              <ListItemText primary="Booking Total" classes={{primary:classes.totalRow}}/>
                              <ListItemSecondaryAction className={classes.totalRow}>
                              { this.state.selectedBoatPrice || "$ 0"}
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        </Grid>
                        
                      </Grid>
                      <Box p={1} />
                      <Grid container xs={12} >
                          <Grid container style={{marginTop : '40px'}} justify="space-between" spacing={4}>
                            <Grid item md={6} xs={12}>
                              <Typography display="inline" className={classes.rentalTermHeading}>Rental Term :  </Typography>
                              <Typography display="inline" className={classes.rentalTermValue}> Security Deposit $500</Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography display="inline" className={classes.rentalTermHeading}>Cancelation Policy :  </Typography> 
                              <Typography display="inline" className={classes.rentalTermValue}>Moderate</Typography>
                              <Link className={classes.seeDetails}>
                                See Details</Link>
                            </Grid>
                          </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Formik
                        initialValues={{
                          tripDate: "",
                          tripTime: "",
                          noOfPassenger: "",
                          tripDuration: "",
                          captionIncluded: "",
                        }}
                        enableReinitialize
                        validationSchema={boatBookSchema}
                        onSubmit={async (values, actions) => {
                          this.setState({
                            bookingValue : values
                          },()=>{
                            this.createBooking(values)
                          })
                          // this.createBooking(values);
                        }}
                      >
                        {(formikProps) => {
                          const {
                            values,
                            setFieldValue,
                            setFieldTouched,
                            dirty,
                            isValid,
                            errors,
                            touched
                          } = formikProps;
                          return (
                            <Form noValidate autoComplete="off">
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                              >
                                <Grid item xs={12}>
                                  <Typography className={classes.steps}>
                                    Step 1 of 3
                                  </Typography>
                                  <Typography paragraph gutterBottom className={clsx(classes.subHeading,classes.confirmHeading)}>
                                    Confirm your details
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container justify="space-between" spacing={2}>
                                    <Grid item xs={5}>
                                      <Grid container justify="space-between">
                                        <Grid xs={3}>
                                          <img
                                            src={calendar}
                                            width={30}
                                            height={30}
                                          />
                                        </Grid>
                                        <Grid
                                          xs={9}
                                          style={{ alignSelf: "flex-end" }}
                                        >
                                          <Typography
                                            className={
                                              classes.selectPickerHeading
                                            }
                                          >
                                            Trip Date
                                          </Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                          <div style={{ display: "flex" }}>
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                display="inline"
                                                className={classes.sortBy}
                                              >
                                                {values.tripDate
                                                  ? values.tripDate
                                                  : "Select"}
                                              </Typography>
                                              <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                              >
                                                <DatePicker
                                                  disablePast
                                                  style={{ display: "none" }}
                                                  onChange={(data) => {
                                                    setFieldValue(
                                                      "tripDate",
                                                      new Date(
                                                        data
                                                      ).toDateString()
                                                    );
                                                  }}
                                                  value={values.tripDate}
                                                  onClose={() => {
                                                    this.setState({
                                                      isOpenDatePicker: false,
                                                    });
                                                  }}
                                                  open={
                                                    this.state.isOpenDatePicker
                                                  }
                                                />
                                              </MuiPickersUtilsProvider>
                                              <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={() => {
                                                  this.setState({
                                                    isOpenDatePicker: true,
                                                  },()=>{
                                                    setFieldTouched("tripDate",true)
                                                  });
                                                }}
                                              >
                                                <ArrowDropDownIcon />
                                              </IconButton>
                                             {errors.tripDate && touched.tripDate && <Typography
                                                display="block"
                                                color="error"
                                              >
                                                {errors.tripDate}
                                              </Typography>} 
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={5}>
                                      <Grid container justify={"space-between"}>
                                        <Grid xs={2}>
                                          <img
                                            src={watch}
                                            width={30}
                                            height={30}
                                          />
                                        </Grid>
                                        <Grid
                                          xs={9}
                                          style={{ alignSelf: "flex-end" }}
                                        >
                                          <Typography
                                            className={
                                              classes.selectPickerHeading
                                            }
                                          >
                                            Trip Duration
                                          </Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                          <div style={{ display: "flex" }}>
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                display="inline"
                                                className={classes.sortBy}
                                              >
                                                {values?.tripDuration
                                                  ? values?.tripDuration?.name +
                                                    " " 
                                                  : "Select"}
                                              </Typography>
                                              <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={(event)=>{
                                                  this.openDurationMenu(event,setFieldTouched)
                                                }}
                                              >
                                                <ArrowDropDownIcon />
                                              </IconButton>
                                              {errors.tripDuration && touched.tripDuration && <Typography
                                                display="block"
                                                color="error"
                                              >
                                                {errors.tripDuration}
                                              </Typography>}
                                              <Menu
                                                id="simple-menu"
                                                anchorEl={
                                                  this.state.durationAnchorEl
                                                }
                                                keepMounted
                                                open={
                                                  !!this.state.durationAnchorEl
                                                }
                                                onClose={() => {
                                                  this.setState({
                                                    durationAnchorEl: null,
                                                  });
                                                }}
                                              >
                                                {[{name :'4 Hours',value : 'half_day'},
                                                {name :'8 Hours',value : 'full_day'},
                                                {name :'24 Hours',value : 'twentyfour_hours'},
                                                {name :'3 Days',value : 'three_days'},
                                                {name :'7 Days',value : 'seven_days'},
                                                ].map((hour) => {
                                                  return (
                                                    <MenuItem
                                                      onClick={() => {
                                                        setFieldValue(
                                                          "tripDuration",
                                                          hour
                                                        );
                                                        setFieldValue(
                                                          "tripTime",
                                                          null
                                                        );
                                                        this.setState({
                                                          duration : hour
                                                        },()=>{
                                                          const boatPrice = this.getPrice(this.state.boatDetails?.boat_price,this.state.duration.value);
                                                          this.setState({
                                                            selectedBoatPrice : boatPrice
                                                          })
                                                        })
                                                        this.selectDurationAndCloseMenu();
                                                      }}
                                                    >
                                                      {hour.name}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Menu>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    

                                    <Grid item xs={5}>
                                      <Grid container justify={"space-between"}>
                                        <Grid xs={2}>
                                          <img
                                            src={team}
                                            width={30}
                                            height={30}
                                          />
                                        </Grid>
                                        <Grid
                                          xs={9}
                                          style={{ alignSelf: "flex-end" }}
                                        >
                                          <Typography
                                            className={
                                              classes.selectPickerHeading
                                            }
                                          >
                                            Passengers
                                          </Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                          <div style={{ display: "flex" }}>
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                display="inline"
                                                className={classes.sortBy}
                                              >
                                                {values.noOfPassenger
                                                  ? values.noOfPassenger +
                                                    " " +
                                                    "Passengers"
                                                  : "Select"}
                                              </Typography>
                                              <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={ (event) => {
                                                  this.openPassengersMenu(event,setFieldTouched) 
                                                }}
                                              >
                                                <ArrowDropDownIcon />
                                              </IconButton>
                                              {errors.noOfPassenger && touched.noOfPassenger && <Typography
                                                display="block"
                                                color="error"
                                              >
                                                {errors.noOfPassenger}
                                              </Typography>}
                                              <Menu
                                                id="simple-menu"
                                                anchorEl={
                                                  this.state.passengersAnchorEl
                                                }
                                                keepMounted
                                                open={
                                                  !!this.state
                                                    .passengersAnchorEl
                                                }
                                                onClose={() => {
                                                  this.setState({
                                                    passengersAnchorEl: null,
                                                  });
                                                }}
                                              > {
                                                new Array(this.state.boatDetails?.max_passengers || 0).fill(0).map((pas,index)=>{
                                                    return <MenuItem
                                                     key={index}
                                                    onClick={() => {
                                                      setFieldValue(
                                                        "noOfPassenger",
                                                        index+1
                                                      );
                                                      this.selectPassengersAndCloseMenu();
                                                    }}
                                                  >
                                                    {index+1} Passenger
                                                  </MenuItem>
                                                })
                                              }
                                              </Menu>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {values.tripDuration?.value === 'half_day' ? <Grid item xs={5}>
                                      <Grid container justify={"space-between"}>
                                      <Grid xs={2} item>
                                          <img
                                            src={clock}
                                            width={30}
                                            height={30}
                                          />
                                        </Grid>
                                        <Grid
                                          xs={9}
                                          style={{ alignSelf: "flex-end" }}
                                        >
                                          <Typography
                                            className={
                                              classes.selectPickerHeading
                                            }
                                          >
                                            Time
                                          </Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                          <div style={{ display: "flex" }}>
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                display="inline"
                                                className={classes.sortBy}
                                              >
                                                {values.tripTime?.time
                                                  ? values.tripTime.time : values.tripTime ?
                                                  values.tripTime : 
                                                   "Select"}
                                              </Typography>
                                              <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={ (event) => {
                                                  this.openTripTimeMenu(event,setFieldTouched) 
                                                }}
                                              >
                                                <ArrowDropDownIcon />
                                              </IconButton>
                                              {errors.tripTime && touched.tripTime && <Typography
                                                display="block"
                                                color="error"
                                              >
                                                {errors.tripTime}
                                              </Typography>}
                                              <Menu
                                                id="simple-menu"
                                                anchorEl={
                                                  this.state.tripTimeAnchorEl
                                                }
                                                keepMounted
                                                open={
                                                  !!this.state
                                                    .tripTimeAnchorEl
                                                }
                                                onClose={() => {
                                                  this.setState({
                                                    tripTimeAnchorEl: null,
                                                  });
                                                }}
                                              > 
                                               {
                                                shiftDays.map((shift,index)=>{
                                                    return <MenuItem
                                                     key={index}
                                                    onClick={() => {
                                                      setFieldValue(
                                                        "tripTime",
                                                        shift
                                                      );     
                                                      this.selectTripTimeAndCloseMenu();
                                                    }}
                                                  >
                                                    {shift.time}
                                                  </MenuItem>
                                                })
                                              }
                                              </Menu>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid> : 
                                      <Grid item xs={5}>
                                      <Grid container justify={"space-between"}>
                                        <Grid xs={2}>
                                          <img
                                            src={clock}
                                            width={30}
                                            height={30}
                                          />
                                        </Grid>
                                        <Grid
                                          xs={9}
                                          style={{ alignSelf: "flex-end" }}
                                        >
                                          <Typography
                                            className={
                                              classes.selectPickerHeading
                                            }
                                          >
                                            Time
                                          </Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                          <div style={{ display: "flex" }}>
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                display="inline"
                                                className={classes.sortBy}
                                              >
                                                {values.tripTime?.time
                                                  ? new Date(values.tripTime?.time).toLocaleString(
                                                    "en-US",
                                                    {
                                                      hour: "numeric",
                                                      hour12: true,
                                                    }
                                                  )
                                                  : "Select"}
                                              </Typography>
                                              <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                              >
                                                <TimePicker
                                                  disablePast
                                                  style={{ display: "none" }}
                                                  views={["hours"]}
                                                  onChange={(data) => {
                                                    setFieldValue(
                                                      "tripTime",
                                                      {time : new Date(
                                                        data
                                                      )}
                                                      
                                                    );
                                                  }}
                                                  value={values.tripTime}
                                                  onClose={() => {
                                                    this.setState({
                                                      isOpenTimePicker: false,
                                                    },()=>{
                                                      setFieldTouched("tripTime",true)
                                                    });
                                                  }}
                                                  open={
                                                    this.state.isOpenTimePicker
                                                  }
                                                />
                                              </MuiPickersUtilsProvider>
                                              <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={() => {
                                                  this.setState({
                                                    isOpenTimePicker: true,
                                                  });
                                                }}
                                              >
                                                <ArrowDropDownIcon />
                                              </IconButton>
                                              {errors.tripTime && touched.tripTime && <Typography
                                                display="block"
                                                color="error"
                                              >
                                                {errors.tripTime}
                                              </Typography>}
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    } 
                                      
                                    
                                    <Grid item xs={5}>
                                      <Grid container justify={"space-between"}>
                                        <Grid xs={2}>
                                          <img
                                            src={captionIcon}
                                            width={30}
                                            height={30}
                                          />
                                        </Grid>
                                        <Grid
                                          xs={9}
                                          style={{ alignSelf: "flex-end" }}
                                        >
                                          <Typography
                                            className={
                                              classes.selectPickerHeading
                                            }
                                          >
                                            Captain
                                          </Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                          <div style={{ display: "flex" }}>
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                display="inline"
                                                className={classes.sortBy}
                                              >
                                                {values.captionIncluded
                                                  ? values.captionIncluded +
                                                    " " +
                                                    "Included"
                                                  : "Select"}
                                              </Typography>
                                              <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={(event)=>{
                                                  this.openCaptionMenu(event,setFieldTouched)
                                                }}
                                              >
                                                <ArrowDropDownIcon />
                                              </IconButton>
                                              {errors.captionIncluded && touched.captionIncluded && <Typography
                                                display="block"
                                                color="error"
                                              >
                                                {errors.captionIncluded}
                                              </Typography>}
                                              <Menu
                                                id="simple-menu"
                                                anchorEl={
                                                  this.state.captionAnchorEl
                                                }
                                                keepMounted
                                                open={
                                                  !!this.state.captionAnchorEl
                                                }
                                                onClose={() => {
                                                  this.setState({
                                                    captionAnchorEl: null,
                                                  });
                                                }}
                                              >
                                                {["Captain", "No Captain"].map(
                                                  (caption) => {
                                                    return (
                                                      <MenuItem
                                                        onClick={() => {
                                                          setFieldValue(
                                                            "captionIncluded",
                                                            caption
                                                          );
                                                          this.selectCaptionAndCloseMenu();
                                                        }}
                                                      >
                                                        {`${caption} Included`}
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                              </Menu>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <ListItem>
                                      <ListItemIcon>
                                         {isAllDocumentPresent ? <CheckIcon style={{color : 'green'}}></CheckIcon> : <img src={error} alt="error icon"/>}
                                      </ListItemIcon>
                                      <ListItemText
                                        classes={{
                                          primary : classes.approvalTypeMessage,
                                          secondary : classes.approvalTypeMessage
                                        }} 
                                        secondary="Please make sure you've uploaded all the required documents." 
                                        primary="Your request will be approved automatically.">
                                      </ListItemText>
                                  </ListItem>
                                  {isDocumentNotRequired ? <Typography>
                                    No Documents are required to approve your request
                                  </Typography> : required_docs.map((required_doc)=>{
                                        return this.state.boatDetails.required_doc[required_doc]+"" === "true" ? <div key={required_doc} style={{marginLeft : '100px'}}>
                                        <Typography display="inline" style={{color : this.state.userDocuments.indexOf(required_doc) > -1 ? 'green' : 'red',fontSize : '18px'}}>{this.state.userDocuments.indexOf(required_doc) > -1 ? "✓" : "✗"}</Typography>
                                        <Typography display="inline" className={classes.documentListText}>
                                            {required_doc}
                                        </Typography>
                                        </div> : <></>
                                  })}
                                  
                                  
                                </Grid>
                                <Box p={2} />
                                <Grid item xs={12} container justify="center" spacing={2}>
                                
                                    <Grid item md="auto" xs={12} justify="center">
                                     {this.state.boatDetails.owner_approval_status === 'automatic' && <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={()=>{this.props.history.push("/userDashboard/myDocuments")}}
                                        className={classes.buttons}
                                      >
                                        Upload Docs
                                      </Button>
                                      } 
                                      </Grid>
                                      <Grid item md="auto" xs={12} justify="center">
                                      <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        className={classes.buttons}
                                      >
                                        Proceed
                                      </Button>
                                    </Grid>
                                 
                                </Grid>
                              </Grid>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>
                  </Grid>
                  
                </Paper>
              </main>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BookBoatWithRouter = withRouter(BookBoat);
const BookBoatAlertBox = withAlertBox(BookBoatWithRouter);
const BookBoatLoader = withLoader(BookBoatAlertBox);
const BookBoatToast = withToast(BookBoatLoader);
export default withStyles((theme) =>
  createStyles({
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(6),
      },
    },
    root: {
      backgroundImage:
        "linear-gradient(180deg, rgba(0,0,255,1) 30%, rgba(255,255,255,1) 30%)",
    },
    sideBar: {
      height: "calc(100vh - 72px)",
      backgroundColor: "#f5f5f5",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    menuItem: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#343a40",
    },
    margin: {
      marginRight: theme.spacing(2),
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor: "pointer",
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "30px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.23,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#183b56",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      margin: "auto",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "90%",
      border: "solid 1px #fff",
    },
    rightPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
      textAlign: "center",
    },
    rightPageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
      paddingTop: "16px",
    },
    inputLable: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
      padding: "12px",
    },
    approvalBox: {
      width: "100%",
      height: "120px",
      border: "solid 2px #343a40",
      borderRadius: "6px",
    },
    primaryListText: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    secondaryListText: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      paddingTop: "12px",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    checkBoxRoot: {
      display: "flex",
    },
    checkBoxHeading: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    checkBoxLabel: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    logo: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.16,
      letterSpacing: "1px",
      color: "#ffffff",
      marginLeft: "24px",
    },
    sideBarLinks: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#343a40",
    },
    sideBarHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#004277",
      padding: "12px",
    },
    sideBarButton: {
      backgroundColor: "red",
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    sortBy: {
      opacity: 0.59,
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#545454",
    },
    selectPickerHeading: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#0174cf",
      [theme.breakpoints.down(450)]:{
        marginLeft:"7px"
      }
    },
    InputdatepickerInput: {
      display: "none",
    },
    hotelMainImage: {
      position: "relative",
      backgroundImage: `url(${boatImageDemo1})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(15),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(10),
      },
      borderRadius: theme.spacing(1),
      margin:"10px 0px 10px 10px",
     
    },
    boatName: {
      fontFamily: "Roboto",
      fontSize: "2rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#004277",
    },
    mainHeading:{
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#004277",
    },
    subHeading:{
      fontFamily: "Roboto",
      fontSize: "2rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#183b56",
    },
    firstSubHeading:{
      marginTop:"10px",
      [theme.breakpoints.up('md')]:{
        marginTop:"27px"
      }
    },
    boatAddress: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#545454",
    },
     amount:{
      fontFamily: "Roboto",
      fontSize: "1.125rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
     },
     totalRow:{
      fontFamily: "Roboto",
      fontSize: "1.125rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
     },
     rentalTermHeading : {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#183b56",
    },
    rentalTermValue : {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#183b56",
    },
    seeDetails:{
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#0174cf",
      marginLeft:"8px"
    },
    steps:{
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#183b56",
      marginBottom:"8px"
    },
    confirmHeading:{
      marginBottom:"28px"
    },
    approvalTypeMessage : {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.56,
      letterSpacing: "normal",
      color: "#343a40",
    },
    documentListText : {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.56,
      letterSpacing: "normal",
      color: "#343a40",
    },
    buttons:{
      fontFamily:"Montserrat",
      borderRadius:"5px",
      width:"150px"
    }
  })
)(withDialog(BookBoatToast));
