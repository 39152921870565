//@ts-nocheck
import React from "react";
import CustomerPernoalInfoController, {
  Props,
} from "./CustomerPernoalInfoController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Hidden,
  Paper
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import { boatDetail } from "./assets";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputField from "../../../components/src/InputFiled.web";
import { NavLink } from "react-router-dom";
import moment from "moment";
import "yup-phone";

const sideBarLinks = [
  { displayName: "My Documents", route: "/userDashboard/myDocuments" },
  { displayName: "My Bookings", route: "/userDashboard/myBookings" },
  { displayName: "Messages", route: "/userDashboard/messages" },
  {
    displayName: "Personal Information",
    route: "/userDashboard/personalInformation",
  },
  { displayName: "Payment", route: "/userDashboard/payment" },
  { displayName: "Account Settings", route: "/userDashboard/accountSettings" },
];
const date = new Date().setFullYear(new Date().getFullYear() - 18)
const curDate = moment(new Date(date)).format("YYYY-MM-DD").toString();
const Schema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^[ A-Za-z_@./#&+-]*$/,
      "First name should contain alphabet only"
    )
    .required("This field is required"),
  lastName : Yup.string()
  .matches(
    /^[ A-Za-z_@./#&+-]*$/,
    "Last name should contain alphabet only"
  )
  .required("This field is required"),
  phone : Yup.string().phone().required("This field is required."),
  email : Yup.string()
  .email("Please enter valid email address")
  .required("This field is required"),
  dob : Yup.string()
  .required("This field is required")
});

class CustomerPernoalInfo extends CustomerPernoalInfoController {
  fileInputRef = React.createRef();  
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState({
        authToken: authToken,
      },()=>{
          this.getPersonalInfo()
      });
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  goToSelectedRoute = (routeName) => {
    this.handleClose();
    this.setState({ mobileOpen: false });
    const { history } = this.props;
    history.push(routeName);
  };
  toggleInfo = (value) => {
      this.setState({
          mode : value
      })
  }
  fileInputClicked() {
    this.fileInputRef?.current?.click();
  }
  filesSelected = () => {
    if (this.fileInputRef?.current?.files?.length) {
        this.handleFiles(this.fileInputRef?.current?.files);
    }
  }
  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({base64 : reader.result,file : file,id : new Date().getTime()});
      reader.onerror = error => reject(error);
    });
  };

  tobase64Handler = async (files) => {
    const filePathsPromises = [];
    files.forEach(file => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      profilePicturePreView : [...filePaths]
    })
  }

  handleFiles(files: FileList){
    const filepaths = this.tobase64Handler([...files]);
  }
  render() {
    const { classes } = this.props;
    return <Grid container className={classes.root}>
    <>
      <Hidden xsDown>
        <Grid item xs={0} sm={1} md={2} container className={classes.sideBar}>
          <Grid item xs={12}>
            <Typography align="center" className={classes.sideBarHeading}>
              My Account
            </Typography>
            <List dense={false}>
              {sideBarLinks.map((item) => {
                return (
                  <NavLink
                    to={item.route}
                    className={classes.sideLink}
                    activeClassName={classes.activeSideLink}
                    key={item.displayName}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <AccountCircle />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.displayName}
                        classes={{ primary: classes.sideLink }}
                      />
                    </ListItem>
                  </NavLink>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={11} md={10} className={classes.rightRoot}>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <>
              <Typography className={classes.pageHeading} gutterBottom>
                Personal Information
              </Typography>
              <Typography className={classes.pageSubHeading}>
                You can update and change your information here
              </Typography>
              <Grid
                container
                style={{ marginTop: "30px" }}
                spacing={8}
                justify={"space-between"}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Typography className={classes.sectionHeading} gutterBottom>
                    1. Profile Photo
                  </Typography>
                  <Grid container justify="space-around" spacing={2}>
                    <Grid item xs={3}>
                      <Typography className={classes.preView} gutterBottom>
                        Preview
                      </Typography>
                      <Avatar
                        style={{ width: "100px", height: "100px" }}
                        src={
                          this.state?.profilePicturePreView?.length > 0
                            ? this.state.profilePicturePreView[0].base64
                            : this.state.personalInfo?.avatar?.url ? this.state.personalInfo?.avatar?.url : null
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => {
                          this.fileInputClicked();
                        }}
                      >
                        Update
                      </Button>
                      <input
                        ref={this.fileInputRef}
                        className={classes.fileInput}
                        type="file"
                        accept="image/*"
                        multiple={false}
                        onChange={this.filesSelected.bind(this)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.updateProfileImage()}
                        disabled={!this.state.profilePicturePreView}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      firstName: this?.state?.personalInfo?.first_name
                        ? this?.state?.personalInfo?.first_name
                        : "",
                      lastName: this?.state?.personalInfo?.last_name
                        ? this?.state?.personalInfo?.last_name
                        : "",
                      phone: this?.state?.personalInfo?.phone_number
                        ? this?.state?.personalInfo?.phone_number
                        : "",
                      email: this?.state?.personalInfo?.email
                        ? this?.state?.personalInfo?.email
                        : "",
                      dob: this?.state?.personalInfo?.date_of_birth
                        ? this?.state?.personalInfo?.date_of_birth
                        : "",
                    }}
                    validationSchema={Schema}
                    onSubmit={(values, actions) => {
                      if (this.state.mode === "Edit") {
                        this.setState(
                          {
                            mode: "View",
                          },
                          () => {
                            this.updateUserInfo(values);
                          }
                        );
                      }
                    }}
                  >
                    {(formikProps) => {
                      const {
                        values,
                        handleBlur,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                      } = formikProps;
                      return (
                        <Form autoComplete="off">
                          <Grid container justify="space-between" spacing={2}>
                            <Grid item xs={8}>
                              <Typography
                                className={classes.sectionHeading}
                                gutterBottom
                              >
                                2. Contact Info
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              {this.state.mode === "View" ? (
                                <Button
                                  variant="text"
                                  color="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    this.toggleInfo("Edit");
                                  }}
                                >
                                  Edit
                                </Button>
                              ) : (
                                <Button onClick={handleSubmit} variant="contained" color="primary">
                                  Save
                                </Button>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {this.state.mode === "View" ? (
                                <ListItem>
                                  <ListItemText
                                    primary="First Name"
                                    secondary={values.firstName}
                                    classes={{
                                      primary: classes.primaryText,
                                      secondary: classes.secondaryText,
                                    }}
                                  />
                                </ListItem>
                              ) : (
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="First Name"
                                  name="firstName"
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {this.state.mode === "View" ? (
                                <ListItem>
                                  <ListItemText
                                    primary="Last Name"
                                    secondary={values.lastName}
                                    classes={{
                                      primary: classes.primaryText,
                                      secondary: classes.secondaryText,
                                    }}
                                  />
                                </ListItem>
                              ) : (
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="Last Name"
                                  name="lastName"
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {this.state.mode === "View" ? (
                                <ListItem>
                                  <ListItemText
                                    primary="Email ID"
                                    secondary={values.email}
                                    classes={{
                                      primary: classes.primaryText,
                                      secondary: classes.secondaryText,
                                    }}
                                  />
                                </ListItem>
                              ) : (
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="Email ID"
                                  name="email"
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {this.state.mode === "View" ? (
                                <ListItem>
                                  <ListItemText
                                    primary="Phone Number"
                                    secondary={values.phone}
                                    classes={{
                                      primary: classes.primaryText,
                                      secondary: classes.secondaryText,
                                    }}
                                  />
                                </ListItem>
                              ) : (
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="Phone Number"
                                  name="phone"
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {this.state.mode === "View" ? (
                                <ListItem>
                                  <ListItemText
                                    primary="Date of Birth"
                                    secondary={values.dob}
                                    classes={{
                                      primary: classes.primaryText,
                                      secondary: classes.secondaryText,
                                    }}
                                  />
                                </ListItem>
                              ) : (
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  type="date"
                                  InputProps={{
                                    inputProps: {
                                      max: curDate,
                                    },
                                  }}
                                  label="Date of Birth"
                                  name="dob"
                                  
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                </Grid>
              </Grid>
            </>
          </Paper>
        </main>
      </Grid>
    </>
  </Grid>
  
  }
}
const CustomerPernoalInfoWithRouter = withRouter(CustomerPernoalInfo);
const CustomerPernoalInfoAlertBox = withAlertBox(CustomerPernoalInfoWithRouter);
const CustomerPernoalInfoLoader = withLoader(CustomerPernoalInfoAlertBox);
const CustomerPernoalInfoToast = withToast(CustomerPernoalInfoLoader);
export default withStyles((theme) =>
  createStyles({
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    fileInput : {
        display: 'none'
    },
    sectionHeading : {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    preView : {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#000000"
    },
    primaryText : {
      fontFamily: "Montserrat",
      fontSize: "10px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40"
    },
    secondaryText : {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40"
    },
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(4),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    sideBar: {
      height: "calc(100vh - 72px)",
      backgroundColor: "#ffffff",
    },
    rightRoot: {
      backgroundImage:
        "linear-gradient(180deg, rgba(0,0,255,1) 30%, rgba(255,255,255,1) 30%)",
    },
    sideLinkHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    sideLink: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#343a40",
      cursor: "pointer",
      textAlign: "center",
      display: "block",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
        textTransform: "none",
      },
    },
    activeSideLink: {
      backgroundColor: "rgb(1,116,207,0.1)",
      color: "#343a40",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
    
    
  })
)(withDialog(CustomerPernoalInfoToast));
